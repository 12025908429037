:root {
    --light-color: #FCFCC3;
    --medium-color: #FCD875;
    --dark-color: #FF842C;

    --default-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;

    --navigation-color: #EEEEEE;
    --navigation-link-color: black;
    --navigation-link-active-color: #DDDDDD;

    --footer-color: #EEEEEE;
}