.services {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.services__heading {
    text-align: center;
}

.services__list {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.services__list__item {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.services__list__item__name {
    text-align: center;
    width: 300px;
    margin-left: 20px;
}

.services__list__item__description {
    text-indent: 20px;
    width: 400px;
    margin-left: 20px;
    padding-right: 10px;
    text-wrap: pretty;
}

.services__list__item__emoji {
    font-size: 40px;
    padding-left: 10px;
}

.services__get-in-touch {
    margin-top: 40px;
    align-self: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 20px;
    font-weight: 700;
    font-family: var(--default-font);
    text-decoration: none;
    color: white;
    background-color: var(--dark-color);
    cursor: pointer;
}

/* Phone. */
@media screen and (max-width: 800px) {
    .services__list__item {
        flex-direction: column;
    }

    .services__list__item__name {
        margin-bottom: 10px;
    }

    .services__list__item__description {
        width: 300px;
    }
}