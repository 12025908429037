.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(50px + 60px);
    margin-bottom: 100px;
}

/* General classes. */

.heading--1 {
    font-size: 46px;
    font-weight: 600;
}

.medium--text {
    font-weight: 600;
    font-size: 16px;
}

.large--text {
    font-weight: 600;
    font-size: 26px;
}