.contact {
    display: flex;
    flex-direction: column;

    --input-width: 500px;
}

.contact__heading {
    text-align: center;
}

.contact__form__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    background-color: var(--medium-color);
    box-shadow: 2px -4px 20px 2px rgba(0, 0, 0, 0.15);

    overflow: hidden;
    transition: height 1s ease;
    height: 500px;
    max-height: 1000px;
}

.hide--form {
    height: 0;
}

.contact__form__row {
    margin-bottom: 10px;
}

.contact__form__name {
    width: var(--input-width);
    font-family: var(--default-font);
    border: none;
    padding: 10px;
}

.name--row {
    background-color: var(--light-color);
    border-radius: 0% 0% 00% 90%;
    padding: 20px;
}

.contact__form__email {
    width: var(--input-width);
    font-family: var(--default-font);
    border: none;
    padding: 10px;
    
}

.contact__form__message {
    width: var(--input-width);
    font-family: var(--default-font);
    resize: none;
    border: none;
    padding: 10px;
    height: 241px;
}

.message--row {
    background-color: var(--dark-color);
    border-radius: 0% 90% 0% 0%;
    padding: 20px;
    margin-bottom: 0;
}

.contact__form__send {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--default-font);
    background-color: white;
    cursor: pointer;
}

.send--row {
    background-color: var(--dark-color);
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}

.contact__success-message {
    width: var(--input-width);
    text-align: center;
    visibility: hidden;
    align-self: center;
}

.show--message {
    visibility: visible;
}

/* Phone. */
@media screen and (max-width: 800px) {
    .contact {
        --input-width: 250px;
    }
}