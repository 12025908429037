.navigation {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: var(--navigation-color);
    z-index: 999;
    
    /* For phone. */
    transition: height 0.3s ease;
}

.navigation--expansion {
    height: 50px;
}

.navigation__logo {
    position: absolute;
    top: 15px;
    margin-left: 50px;
    max-height: 80px;

    /* Click stuff. */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s;
}

.navigation__logo:hover {
    box-shadow: -2px 4px 4px 2px rgba(0, 0, 0, 0.2);
}

.navigation__logo:active {
    transform: translateY(5px);
}

.navigation__link__container {
    display: flex;
    margin-left: 160px;
    overflow: hidden;
}

.navigation--visibility {
    display: flex;
}

.navigation__link {
    position: relative;
    margin: 5px;
    padding: 5px;
    text-decoration: none;
    color: var(--navigation-link-color);
}

.navigation__link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 50%;
    background-color: var(--navigation-link-active-color);
    z-index: -1;
    transition: width 0.3s ease;
}

.navigation__link::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 50%;
    background-color: var(--navigation-link-active-color);
    z-index: -1;
    transition: width 0.3s ease;
}

.navigation__link.navigation__link--active::before {
    width: 100%;
}

.navigation__link.navigation__link--active::after {
    width: 100%;
}

.navigation__hamburger {
    position: absolute;
    top: 5px;
    right: 10px;
    display: none;
    font-size: 32px;
    padding: 0px; /* HTML buttons have weird padding */
    color: var(--navigation-link-color);
    
    /* Hide ugly button. */
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

/* Phone. */
@media screen and (max-width: 800px) {
    .navigation {
        align-items: flex-start;
    }

    .navigation--expansion {
        height: 160px;
    }

    .navigation__logo {
        margin-left: 20px;
    }

    .navigation__link__container {
        flex-direction: column;
        margin-left: 120px;
        margin-top: 10px;
        height: inherit;
    }

    .navigation--visibility {
        display: none;
    }

    .navigation__hamburger {
        display: block;
    }
}