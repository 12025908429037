.home {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.home__showstopper {
    display: flex;
    flex-direction: column;
    background-color: #FCD875;
}

.home__showstopper__top-pad {
    width: 100%;
    height: 72px;
    background-color: #FCFCC3;
    z-index: 1;
}

.home__showstopper__content {
    display: flex;
    margin: 10px;
}

.home__showstopper__static-text {
    margin-left: 50px;
}

.home__showstopper__dynamic-text {
    margin-left: 15px;
    transform: translateY(-100%);
    transition: transform 1s ease;
}

.roll--hide {
    visibility: hidden;
}

.roll--animation-1 {
    transform: translateY(0%);
}

.roll--animation-2 {
    transform: translateY(100%);
}

.home__showstopper__bottom-pad {
    width: 100%;
    height: 72px;
    background-color: #FF842C;
    z-index: 1;
}

.home__description {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    margin-top: 50px;
    align-self: center;
}

.home__description__greeting {

}

.home__description__content {
    margin-top: 20px;
}

.home__description__link {
    margin-top: 20px;
    color: var(--dark-color);
}
/* Phones. */
@media screen and (max-width: 800px) {
    .home__showstopper__static-text {
        display: none;
    }

    .home__showstopper__content {
        height: 120px;
    }

    .home__showstopper__dynamic-text {
        transform: translateX(-100vw);
    }
    
    .roll--animation-1 {
        transform: translateX(0%);
    }

    .roll--animation-2 {
        transform: translateX(100vw);
    }
}


