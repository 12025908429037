.the-team {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.the-team__heading {
    text-align: center;
}

.the-team__container {
    margin-top: 60px;
    background-color:#FCD875 ;
    display: flex;
    flex-direction: column;
}

.the-team__row {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
}

.ahmed--background {
    border-radius: 0% 0% 00% 90%;
    background-color: #FCFCC3;
}

.david--background {
    border-radius: 0% 90% 0% 0%;
    background-color:#FF842C;
}

.the-team__selfie {
    height: 250px;
    width: 250px;
    box-shadow: -2px 4px 4px 2px rgba(0, 0, 0, 0.3);
    margin: 20px
}

.the-team__details {
    display: flex;
    flex-direction: column;
}

.the-team__name {
    color: black;
    font-weight: 700;
    font-size: 24px;
}

.the-team__bio {
    margin-top: 5px;
    width: 400px;
    color: black;
    text-indent: 20px;
}

/* Phone. */
@media screen and (max-width: 800px) {

    .the-team__row {
        flex-direction: column;
    }

    .the-team__bio {
        width: 300px;
    }
}