.footer {
    width: 100%;
    height: 50px;
    background-color: var(--footer-color);
    margin-top: auto;

    display: flex;
    align-items: center;
}

.footer__text {
    color: #444444;
    font-weight: 600;
    margin-left: 20px;
}